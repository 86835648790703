import TYPES from '@/types';
import Vue from 'vue';

// Application
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import GetRetirementForecastCalculationQuery
  from '@/modules/flagship/retirement-forecast-calculation/application/queries/get-retirement-forecast-calculation-query';
import GetPersonalInfoQuery
  from '@/modules/flagship/investor-profile/personal-info/application/queries/get-personal-info-query';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class RetirementFundAgeViewModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.GET_PERSONAL_INFO_QUERY)
  private readonly get_personal_info_query!: GetPersonalInfoQuery;

  @Inject(TYPES.GET_RETIREMENT_FORECAST_CALCULATION_QUERY)
  private readonly get_forecast_calculation_query!: GetRetirementForecastCalculationQuery;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-retirement-fund-goal-plan.edit_retirement_fund_retirement_age';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  readonly min_age_to_retirement = 55;

  readonly max_age_to_retirement = 75;

  plan_information = [
    {
      label: this.translate('current_age'),
      value: '',
    },
    {
      label: this.translate('retirement'),
      value: '',
    },
    {
      label: this.translate('pension'),
      value: '',
    },
    {
      label: this.translate('life_expectancy'),
      value: '',
    },
  ]

  retirement_age = 0;

  current_user_age = 0;

  pension = 0;

  lifetime_expected = 0;

  investor_goal_state = this.create_investor_goal_state_manager.state;

  initialize = async () => {
    await this.loadRetirementFundInvestorGoalInformation();
  }

  setAgeToRetirement = () => {
    this.plan_information[1].value = `${this.retirement_age - this.current_user_age} ${this.translate('years')}`;
    this.plan_information[2].value = `${this.lifetime_expected - this.retirement_age} ${this.translate('years')}`;
  }

  getCurrentAge = async () => {
    const { person } = await this.get_personal_info_query.execute(
      this.investor_goal_state.investor_goal.investor_profile_id,
    );
    this.investor_goal_state.retirement_fund.issued_age = person.current_age;
  }

  loadRetirementFundInvestorGoalInformation = async () => {
    if (this.investor_goal_state.retirement_fund.issued_age === 0) {
      await this.getCurrentAge();
    }
    this.current_user_age = this.investor_goal_state.retirement_fund.issued_age;
    this.retirement_age = (this.investor_goal_state.retirement_fund.retirement_age_adjusted
      < this.min_age_to_retirement) ? this
        .min_age_to_retirement : this.investor_goal_state.retirement_fund.retirement_age_adjusted;
    this.lifetime_expected = this.investor_goal_state.retirement_fund.lifetime_expected;
    this.pension = this.lifetime_expected - this.retirement_age;

    this.plan_information[0].value = `${this.current_user_age} ${this.translate('years')}`;
    this.plan_information[1].value = `${this.retirement_age - this.current_user_age} ${this.translate('years')}`;
    this.plan_information[2].value = `${this.pension} ${this.translate('years')}`;
    this.plan_information[3].value = `${this.lifetime_expected} ${this.translate('years')}`;
  }

  setState = () => {
    this.investor_goal_state.retirement_fund.retirement_age_adjusted = this.retirement_age;
    this.investor_goal_state.retirement_fund.retirement_range_adjusted = (this.retirement_age
      - this.current_user_age);
    this.investor_goal_state.retirement_fund.pension_range_adjusted = (this.lifetime_expected
      - this.retirement_age);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      await this.setState();
      this.view.$emit('nextStep');
      this.investor_goal_state.is_loading = false;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.save'));
    }
  }
}
